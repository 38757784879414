body {
  margin: 0;
  font-family: "Roboto Mono", monospace, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.close {
  position: absolute;
  top: 10ox;
  right: 15px;
  border: none;
  cursor: pointer;
  background: transparent;
  color: #fff;
}

.form-control {
  width: 50%;
  padding: 0 8px;
}

.field {
  width: 100px;
  background: #000;
  color: #fff;
  border: 1px solid #fff;
}

.radio {
  display: flex;
}

.radio__option {
  margin-right: 10px;
}